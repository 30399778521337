import React from 'react';

const ImageModal = ({ src, alt, onClose }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-90 flex items-center justify-center" onClick={onClose}>
            <div className="max-w-4xl max-h-full p-4">
                <img src={src} alt={alt} className="max-w-full max-h-full object-contain" onClick={(e) => e.stopPropagation()} />
            </div>
        </div>
    );
};

export default ImageModal;