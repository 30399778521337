import {setUserConfig} from './Lib/server-api';
import {isMobile, isTablet} from 'react-device-detect';
import {Capacitor} from '@capacitor/core';
import Parser, {domToReact, attributesToProps} from 'html-react-parser';
import DOMPurify from 'dompurify';
import React from 'react';
import CopyButton from './SmallComponents/CopyButton';
import {Browser} from '@capacitor/browser';
import {getEmbedding} from './Lib/open-ai';
import {VectorDB} from './Lib/idb-vector';

const mobileWidthThreshold = 600;
const kConversationDBKey = 'ChatAIConversation';
const kConversationIdListKey = 'ChatAIConversationIdList';

const copySvg = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                     stroke="currentColor" className="w-5 h-5">
    <path strokeLinecap="round" strokeLinejoin="round"
          d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"/>
</svg>;

const svgIcon =
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-3 h-3 inline">
        <path fillRule="evenodd"
              d="M15.75 2.25H21a.75.75 0 01.75.75v5.25a.75.75 0 01-1.5 0V4.81L8.03 17.03a.75.75 0 01-1.06-1.06L19.19 3.75h-3.44a.75.75 0 010-1.5zm-10.5 4.5a1.5 1.5 0 00-1.5 1.5v10.5a1.5 1.5 0 001.5 1.5h10.5a1.5 1.5 0 001.5-1.5V10.5a.75.75 0 011.5 0v8.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V8.25a3 3 0 013-3h8.25a.75.75 0 010 1.5H5.25z"
              clipRule="evenodd"/>
    </svg>
;

const _isIpadSlideOver = () => {
    const aspectRatio = window.innerWidth / window.innerHeight;

    // Check if the device is a tablet and if the window width is less than a specific threshold.
    // Adjust the threshold based on your requirements or actual testing on an iPad.
    const slideOverWidthThreshold = 600;

    return isTablet && (aspectRatio < 1) && (window.innerWidth < slideOverWidthThreshold);
};

/**
 * PWA and desktop on mobile
 * @return {boolean|*}
 */
const isMobileVersion = () => {
    return window.innerWidth < mobileWidthThreshold || _isIpadSlideOver() || isMobile;
};


/**
 * PWA and client on desktop
 * @return {boolean|boolean}
 */
const isDesktopVersion = () => {
    return !isMobileVersion();
};


/**
 * desktop client
 * @return {boolean}
 */
function isElectron() {
    // Renderer process
    if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
        return true;
    }

    // Main process
    if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
        return true;
    }

    // Detect the user agent when the `nodeIntegration` option is set to true
    if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
        return true;
    }

    return false;
}

function isAndroidApk() {
    return Capacitor.getPlatform() === 'android';
}

/**
 * all clients including desktop and mobile
 * @return {boolean}
 */
function isClient() {
    return !!(isElectron() || Capacitor.getPlatform() !== 'web');
}


/**
 * mobile client that need hide buy button
 * @return {boolean}
 */
function isStoreVersion() {
    if (isElectron()) {
        return false;
    }
    if (Capacitor.getPlatform() === 'ios') {
        return true;
    }
    if (Capacitor.getPlatform() === 'android') {
        return false;  //todo: check if it is store version
    }

    return false;
}


/**
 * config structure:
 * {
 * token: 'token',
 * platform: 'platform', // 'openai' or 'azure'
 * modelName: 'model', // 'gpt-3.5-turbo' or 'gpt-4'
 * showUsage: true,
 * }
 */


/**
 * @returns {{ token:string, platform:string, prompt:string, workingMode:string, showUsage:boolean, modelName:string, warningLimit:number, globalMemory:boolean, autoScroll:boolean }}
 */
function getConfig() {
    const defaultConfig = {
        modelName: 'gpt-3.5-turbo',  // without versions
        workingMode: 'cloud', // 'cloud' or 'local'
        token: null,
        warningLimit: 0,  // how many points when warning email is sent. default to 0, no warning email.
        platform: 'openai',
        showUsage: true,
        autoScroll: true,
        globalMemory: false, // default to false
        prompt: `You are an AI assistant called "Chat-AI" or "落格智聊" in Chinese, you are helpful, creative, clever, friendly and honest. Every code block must rendered as markdown with the program language name, inline code will be wrapped by backtick mark. All the formatting must be done by markdown. Render references as normal list with link instead of footnote.\nCurrent date: ${new Date().toISOString().slice(0, 10)}`
    };
    // read config from local storage
    const configString = localStorage.getItem('config');
    // parse config from string to object
    const configObj = JSON.parse(configString);
    // return config object
    return {...defaultConfig, ...configObj};
}


function saveConfigToLocalStorage(config, user = null) {
    if (user) {
        setUserConfig(user, config);
    }
    // stringify config object to string
    const configStr = JSON.stringify(config);
    // save config to local storage
    localStorage.setItem('config', configStr);
}

function migrationConversationFromLocalStorageToIndexedDBIfNeeded() {
    const conversationString = localStorage.getItem('conversation');
    if (!conversationString) {
        return;
    }
    const conversationObj = JSON.parse(conversationString);
    if (!conversationObj) {
        return;
    }

    // set id for each conversation
    let conversationIdList = [];
    for (let i = 0; i < conversationObj.length; i++) {
        conversationObj[i].id = i;
        conversationIdList.push(i);
        setConversation(conversationObj[i]);
    }
    setConversationIdList2DB(conversationIdList);
    localStorage.removeItem('conversation');
}

/**
 * get the conversation id list from local storage [1,2,3,4]
 * @return {number[]}
 */
function getConversationIdListDB() {
    migrationConversationFromLocalStorageToIndexedDBIfNeeded();
    const conversationString = localStorage.getItem(kConversationIdListKey);
    const conversationIdList = JSON.parse(conversationString);
    if (!conversationIdList) {
        return [];
    }
    return conversationIdList;
}

/**
 * save the conversationIdList to local storage [1,2,3,4]
 * @param {number[]} conversationIdList
 */
function setConversationIdList2DB(conversationIdList) {
    const conversationStr = JSON.stringify(conversationIdList);
    localStorage.setItem(kConversationIdListKey, conversationStr);
}

/**
 * get the max id and return maxId + 1
 * @param {number[]} conversationIdList
 */
function nextConversationId(conversationIdList) {
    const tmp = [0, ...conversationIdList];
    return Math.max(...tmp) + 1;
}

/**
 * get conversation by id, from index db, the database name is 'conversations'
 * @param id
 * @return {Promise<{id,title,messages,model,inputCache, imageDetail, imageCache}>} the json object of the conversation {id: 1, title: '新会话', messages: [], model: 'gpt4', inputCache: ''}
 */
async function getConversionById(id) {
    const openRequest = window.indexedDB.open(kConversationDBKey);
    openRequest.onupgradeneeded = function () {
        let db = openRequest.result;
        if (!db.objectStoreNames.contains('conversations')) { // 如果没有 “conversations” 数据
            db.createObjectStore('conversations', {keyPath: 'id'}); // 创造它
        }
    };

    return new Promise((resolve, reject) => {
        openRequest.onsuccess = function () {
            let db = this.result;
            let transaction = db.transaction(['conversations'], 'readonly');
            let objectStore = transaction.objectStore('conversations');
            let request = objectStore.get(id);
            request.onsuccess = function (event) {
                const conversationTemplate = {
                    id: id,
                    title: '',
                    messages: [],
                    model: '',
                    inputCache: '',
                    imageDetail: '',
                    imageCache: [],
                };
                resolve({...conversationTemplate, ...request.result});
            };
            request.onerror = function (event) {
                reject(event);
            };
        };
    });
}

/**
 * set conversation to index db, the database name is 'conversations' {id: 1, title: '新会话', messages: [], model: 'gpt4', inputCache: ''}
 * @param conversation
 * @param {{ token:string, platform:string, prompt:string, workingMode:string, showUsage:boolean, modelName:string, warningLimit:number, globalMemory:boolean, autoScroll:boolean }} config
 * @return {Promise<void>}
 */
async function setConversation(conversation, config = null) {
    if (config && config.globalMemory) {
        await updateVectorDB(conversation);
    }
    const openRequest = window.indexedDB.open(kConversationDBKey);
    openRequest.onupgradeneeded = function () {
        let db = openRequest.result;
        if (!db.objectStoreNames.contains('conversations')) { // 如果没有 “conversations” 数据
            db.createObjectStore('conversations', {keyPath: 'id'}); // 创造它
        }
    };

    return new Promise((resolve, reject) => {
        if (conversation.id < 0) {
            resolve();
            return;
        }
        openRequest.onsuccess = function () {
            let db = this.result;
            let transaction = db.transaction(['conversations'], 'readwrite');
            let objectStore = transaction.objectStore('conversations');
            let request = objectStore.put(conversation);
            request.onsuccess = function (event) {
                resolve(request.result);
            };
            request.onerror = function (event) {
                reject(event);
            };
        };
    });
}

async function removeConversationById(id) {
    const openRequest = window.indexedDB.open(kConversationDBKey);
    openRequest.onupgradeneeded = function () {
        let db = openRequest.result;
        if (!db.objectStoreNames.contains('conversations')) { // 如果没有 “conversations” 数据
            db.createObjectStore('conversations', {keyPath: 'id'}); // 创造它
        }
    };

    return new Promise((resolve, reject) => {
        openRequest.onsuccess = function () {
            let db = this.result;
            let transaction = db.transaction(['conversations'], 'readwrite');
            let objectStore = transaction.objectStore('conversations');
            let request = objectStore.delete(id);
            request.onsuccess = function (event) {
                resolve(request.result);
            };
            request.onerror = function (event) {
                reject(event);
            };
        };
    });
}

async function clearConversations() {
    const openRequest = window.indexedDB.open(kConversationDBKey);
    openRequest.onupgradeneeded = function () {
        let db = openRequest.result;
        if (!db.objectStoreNames.contains('conversations')) { // 如果没有 “conversations” 数据
            db.createObjectStore('conversations', {keyPath: 'id'}); // 创造它
        }
    };

    return new Promise((resolve, reject) => {
        openRequest.onsuccess = function () {
            let db = this.result;
            let transaction = db.transaction(['conversations'], 'readwrite');
            let objectStore = transaction.objectStore('conversations');
            let request = objectStore.clear();
            request.onsuccess = function (event) {
                resolve(request.result);
            };
            request.onerror = function (event) {
                reject(event);
            };
        };
    });
}

async function clearVectorDB() {
    const db = new VectorDB({dbName: 'globalMemory', vectorPath: 'embedding'});
    await db.clearDb();
}

/**
 * update vector db
 * @param {{id: 1, title: '新会话', messages: {sender: 'Human', content: 'Hello', cost: 0.1, fact:'', webContents:['', '']}, model: 'gpt4', inputCache: ''}} conversation
 * @return {Promise<void>}
 */
async function updateVectorDB(conversation) {
    // const oldConversation = await getConversionById(conversation.id);
    // if (oldConversation.messages.length === conversation.messages.length) {
    //     return;
    // }
    // no need to check since now we only call this function when the last msg is from AI
    const timestamp = new Date().toISOString(); // '2024-04-19T13:10:11.717Z'
    // return if the last msg item is not from AI
    if (conversation.messages.length === 0 || conversation.messages[conversation.messages.length - 1].sender !== 'AI') {
        return;
    }
    const db = new VectorDB({dbName: 'globalMemory', vectorPath: 'embedding'});
    // get the last 6 msgs, which is the last 3 from AI and last 3 from Human
    const latestMsgs = conversation.messages.slice(-4);
    // get the input from the msgs, each msg limit to first 500 characters
    const input = latestMsgs.slice(-2).map(msg => msg.content.slice(0, 500)).join('\n');
    const embeddings = await getEmbedding(input);
    await db.insert({embedding: embeddings[0].embedding, 'obj': {timestamp, msgs: latestMsgs}});
}

/**
 * get related msgs from vector db
 * @param input
 * @return {Promise<[{ timestamp, msgs: [{sender: 'Human', content: 'Hello', cost: 0.1, fact:'', webContents:['', '']}] }]>}
 */
async function getRelatedMsgsFromVectorDB(input) {
    const db = new VectorDB({dbName: 'globalMemory', vectorPath: 'embedding'});
    const embeddings = await getEmbedding(input);
    const r = await db.query(embeddings[0].embedding, {limit: 3});
    // return the msgs ordered by timestamp, the first one is the oldest one
    return r.map(item => item.object.obj).sort((a, b) => a.timestamp.localeCompare(b.timestamp));
}

/**
 * [{sender: 'Human', content: 'Hello', cost: 0.1, fact:'', webContents:['', '']}]
 * @param sender
 * @param content
 * @param {string} mode 'local' or 'cloud'
 * @param {number} cost
 * @param {string} fact
 * @param {string[]} webContents
 * @param {boolean} continued // if the message is continued from previous message
 * @param {string[]} images // should be a list of image base64 strings
 * @param {string} imageDetail // low or high, only for gpt-4o use.
 * @returns {any|*[]}
 */
function getChatItem(sender, content, mode = '', cost = 0, fact = '', webContents = [], continued = false, images = [], imageDetail = 'low') {
    return {
        sender,
        content,
        mode,
        cost,
        fact,
        webContents,
        continued,
        images,
        imageDetail,
        // we add uid for each msg, so in future we can use in vector db
        uid: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
    };
}

async function getGlobalMemory(userInput) {
    const objs = await getRelatedMsgsFromVectorDB(userInput);
    let content = 'system: following are the related messages from earlier conversations: \n\n';
    for (let i = 0; i < objs.length; i++) {
        content += `Date: ${objs[i].timestamp} \n`;
        for (let j = 0; j < objs[i].msgs.length; j++) {
            content += `${objs[i].msgs[j].sender === 'Human' ? 'user' : 'ChatAI'}: ${objs[i].msgs[j].content} \n`;
        }
        content += '\n';
    }
    return {
        role: 'user',
        content: content,
    };
}

/**
 * [{sender: 'Human', content: 'Hello', cost: 0.1, fact:''}]
 * @param history
 * @param globalMemory
 * @returns {Promise<{role: string, content: string}[]>}
 */
async function genHistoryForOpenAI(history, globalMemory = false) {
    let histories = [];
    if (globalMemory) {
        const m = await getGlobalMemory(history[history.length - 1].content);
        histories = [m];
    }
    for (let i = 0; i < history.length; i++) {
        const item = history[i];
        if (item.sender === 'SYSTEM') {
            continue;
        }

        if (item.sender === 'AI') {
            if (item.fact) {
                histories.push({
                    role: 'system',
                    content: item.fact
                });
            }

            if (item.webContents && item.webContents.length > 0) {
                for (let j = 0; j < item.webContents.length; j++) {
                    histories.push({
                        role: 'system',
                        content: item.webContents[j]
                    });
                }
            }

            histories.push({
                role: 'assistant',
                content: item.content
            });
        }
        //check if the content is an array
        if (item.sender === 'Human') {
            let content;
            if (item.images && item.images.length > 0) {
                content = []
                for (let j = 0; j < item.images.length; j++) {
                    if (item.images[j] === '') {
                        continue;
                    }
                    content.push({'type': 'image_url', 'image_url': {'url': item.images[j], 'detail': item.imageDetail}});
                }
                content.push({'type': 'text', 'text': item.content});
            } else {
                content = item.content;
            }
            histories.push({role: 'user', content});
        }
    }
    return histories;
}

/**
 * Check if the API is reachable
 * @param {string} url
 * @returns {Promise<boolean>}
 */
async function isApiReachable(url) {
    try {
        const response = await fetch(url, {method: 'HEAD'}); // Using HEAD method for minimal data transfer
        return response.ok; // Will be true if status code is 200-299
    } catch (error) {
        console.error('API not reachable:', error);
        return false;
    }
}


async function getServerAPI(defaultAPI) {

    if (await isApiReachable(defaultAPI)) {
        console.log('default api is reachable 🎉');
        return defaultAPI;
    }

    try {
        const apiList = await fetch('https://api.backup.chatai.beauty/v1/health')
            .then(res => res.json());
        for (const api of apiList.api) {
            const endpoint = 'https://' + api;
            if (endpoint === defaultAPI) {
                continue;
            }
            if (await isApiReachable(endpoint)) {
                return endpoint;
            }
        }
    } catch (e) {
        console.log(e);
    }
    return defaultAPI;
}

/**
 * merge string1 and string2, if string1 and string2 have common prefix, then return string1 + string2.slice(commonPrefix.length)
 * @param str1
 * @param str2
 * @return {*}
 */
function mergeStrings(str1, str2) {
    const minLength = Math.min(str1.length, str2.length);

    for (let i = 0; i < minLength; i++) {
        if (str1[i] !== str2[i]) {
            return str1 + str2.slice(i);
        }
    }

    return str1.length >= str2.length ? str1 : str2;
}

function copyElementToClipboard(event, element) {
    event.preventDefault();
    window.getSelection().removeAllRanges();
    let range = document.createRange();
    range.selectNode(typeof element === 'string' ? document.getElementById(element) : element);
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
}


function parseHtml(html) {
    const parserOptions = {
        replace: ({name, children, parent, attribs}) => {
            if (name === 'code' && parent && parent.name === 'pre') {
                const id = Math.random().toString(36).substring(2, 9);
                return (
                    <div>
                        <CopyButton element={id}/>
                        <code id={id}>
                            {domToReact(children)}
                        </code>
                    </div>

                );
            }

            if (name === 'a') {
                const props = attributesToProps(attribs);
                // open in new tab
                if (isClient() && isMobileVersion()) {
                    // on mobile client, have to use this open link in native browser
                    props.onClick = (event) => {
                        event.preventDefault();
                        Browser.open({url: props.href});
                    };
                }
                return (
                    <a {...props} target="_blank" rel="noopener noreferrer">
                        {domToReact(children)}{svgIcon}
                    </a>
                );

            }
        }
    };
    const sanitizedHtml = DOMPurify.sanitize(html);
    return Parser(sanitizedHtml, parserOptions);
}

function getBase64Size(base64String) {
    const stringLength = base64String.length - 'data:image/jpeg;base64,'.length;
    return 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
}


export {
    getConfig,
    saveConfigToLocalStorage,
    getConversationIdListDB,
    setConversationIdList2DB,
    getConversionById,
    removeConversationById,
    clearConversations,
    setConversation,
    getChatItem,
    genHistoryForOpenAI,
    isMobileVersion,
    isDesktopVersion,
    isClient,
    isElectron,
    getServerAPI,
    isStoreVersion,
    isAndroidApk,
    mergeStrings,
    copyElementToClipboard,
    copySvg,
    parseHtml,
    nextConversationId,
    clearVectorDB,
    getBase64Size,
};
