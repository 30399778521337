import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { GlobalContext } from '../Contexts/GlobalContext';
import { Grid } from 'react-loader-spinner';
import { getConversionById } from '../Utils';

function MobileToolbar() {
    const {
        conversationId,
        flip,
        authUser,
        globalLoading,
        conversationIdList,
    } = useContext(GlobalContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [currentTitle, setCurrentTitle] = useState('');

    useEffect(() => {
        if (!conversationIdList.includes(conversationId)) {
            return;
        }
        let title = t('新会话');
        getConversionById(conversationId).then(conversation => {
            if (conversation) {
                title = conversation.title;
            }
            setCurrentTitle(`${t('会话')}: ${title}...`);
        });
    }, [conversationId, flip]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className={''}>
            <hr className={'border-gray-500'}/>
            {globalLoading ?
                <div className={'my-2 bg-blue-100 dark:bg-slate-800 px-2 rounded py-2 flex items-center justify-center dark:text-gray-500'}>
                    <Grid
                        height="16"
                        width="16"
                        color="#1D4ED8"
                        ariaLabel="grid-loading"
                        radius="10.5"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
                : <div className={'my-2 bg-blue-100 dark:bg-slate-800 px-2 rounded py-1 flex items-center justify-between dark:text-gray-500'}>
                    {currentTitle}
                    {!authUser &&
                        <button className={'my-1 bg-blue-500 dark:bg-slate-600 px-2 rounded py-1 text-white'}
                                onClick={() => navigate('/login')}>{t('登录')}</button>}

                </div>
            }
        </div>
    );
}

export default React.memo(MobileToolbar, (prevProps, nextProps) => {
    return prevProps.getId === nextProps.getId && prevProps.flip === nextProps.flip && prevProps.authUser === nextProps.authUser;
});
