import { RotatingLines } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

function BuyButton(props) {
    const { t } = useTranslation();
    const codeStyle = 'text-red-500 font-mono font-bold';
    return (
        <button
            className="h-16 w-full rounded-xl border-2 border-blue-400 dark:border-slate-400 dark:text-white py-2 md:hover:border-yellow-500 hover:bg-yellow-50 transition-transform shadow-sm duration-500 ease-in-out transform md:hover:-translate-y-1 md:hover:scale-105 md:hover:text-yellow-600 md:hover:dark:bg-yellow-400 md:hover:dark:text-yellow-900  items-center justify-center flex flex-col"
            onClick={props.onClick}>
            {
                props.loading ?
                    <RotatingLines
                        strokeColor="#CA8204ff"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="32"
                        visible={true}
                    />
                :
                <div className={''}>
                    <div className={'text-sm'}>{t('充值金额')} ￥<span className={''}>{props.priceCat}</span> {t('元')} ({t('支付宝')})</div>
                    <div>{t('获得点数')} <span className={codeStyle}>{props.priceValue}</span> {t('点')}</div>
                </div>
            }

        </button>
    );
}

export { BuyButton };
