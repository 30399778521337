import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// import i18n (needs to be bundled ;))
import './i18n';

import Login from './Pages/Login';
import Logout from './Pages/Logout';
import Signup from './Pages/Signup';
import VerifyEmail from './Pages/VerifyEmail';
import ForgotPwd from './Pages/ForgotPwd';
import {
    createBrowserRouter,
    createHashRouter,
    RouterProvider,
} from "react-router-dom";
import { isElectron } from './Utils';
import 'highlight.js/styles/github-dark.css';
import 'simplebar-react/dist/simplebar.min.css';
import ResizeObserver from 'resize-observer-polyfill';
import { Amplify } from 'aws-amplify';

const amplify_config = {
    // OPTIONAL - if your API requires authentication
    Auth: {
        Cognito: {
            region: 'us-east-1', // REQUIRED - Amazon Cognito Region
            userPoolId: 'us-east-1_CAkOVpZlh', // OPTIONAL - Amazon Cognito User Pool ID
            userPoolClientId: '5t2e32ubj1idn7hhkc90j0710e', // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
            // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
            signUpVerificationMethod: 'link', // 'code' | 'link'
            cookieStorage: {
                domain: process.env.REACT_APP_D === 'true' ? 'localhost' : window.location.hostname,
                path: '/',
                expires: 365,
                secure: true,
                sameSite: 'strict',
            },
            loginWith: { // Optional
                oauth: {
                    domain: 'https://chat-ai.auth.us-east-1.amazoncognito.com',
                    scopes: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                    redirectSignIn: process.env.REACT_APP_D === 'true' ? 'http://localhost:3000/' : 'https://chat-ai.logcg.com/',
                    redirectSignOut: process.env.REACT_APP_D === 'true' ? 'http://localhost:3000/' : 'https://chat-ai.logcg.com/',
                    responseType: 'code',
                }
            }
        },
    },
    API: {
        endpoint: 'https://idp.api.logcg.com',
        region: 'us-east-1',
    }
};
Amplify.configure(amplify_config);

window.ResizeObserver = ResizeObserver;

const createRouter = isElectron() ? createHashRouter : createBrowserRouter;

const router = createRouter([
    {
        path: "/",
        element: <App />,
    },
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/signup",
        element: <Signup />,
    },
    {
        path: "/verify-email",
        element: <VerifyEmail />,
    },
    {
        path: "/forgot-password",
        element: <ForgotPwd />,
    },
    {
        path: "/logout",
        element: <Logout />,
    }
]);

if (process.env.REACT_APP_D === 'true') {
    const axe = require('@axe-core/react');
    axe(React, ReactDOM, 1000);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
