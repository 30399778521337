import React, { useState } from 'react';
import { signIn, resendSignUpCode } from 'aws-amplify/auth';
import { RotatingLines } from 'react-loader-spinner';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowLeftCircleIcon } from '@heroicons/react/24/solid';

const Login = () => {
    const { t} = useTranslation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            await signIn({ username, password });
            setError(t('登录成功！'));
            // redirect to home page
            navigate('/');
        } catch (error) {
            setError(error.message);
            if (error.code === 'UserNotConfirmedException') {
                await resendSignUpCode({ username });
                // redirect to verify email page
                navigate('/verify-email', { state: { username: username } }) ;
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="pt-10 md:p-10 justify-center flex flex-col text-center dark:bg-black h-screen">
            <div className={'bg-blue-50 dark:bg-slate-900 py-20 pt-10 mx-5 md:px-10 md:w-1/2 rounded-xl md:mx-auto px-5'}>
                <div className={'text-gray-500 w-full text-left'}>
                    <ArrowLeftCircleIcon className={'w-8 h-8'} onClick={() => navigate(-1)} aria-label={t('返回')}/>
                </div>
                <div className={'md:px-2 mb-10 md:w-1/2 rounded-xl md:mx-auto text-lg font-bold text-gray-500 dark:text-gray-300'}>
                    {t('登录落格智聊')}
                </div>
                <form onSubmit={handleSubmit}  className={'mx-auto max-w-sm'}>
                    <input
                        type="text"
                        className="block border border-gray-400 p-2 mb-2 rounded w-full dark:text-white dark:bg-slate-700 dark:border-slate-700"
                        placeholder={t('用户名')}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <input
                        type="password"
                        className="block border border-gray-400 p-2 mb-2 rounded w-full dark:text-white dark:bg-slate-700 dark:border-slate-700"
                        placeholder={t('密码')}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                        disabled={loading || !username || !password}
                        type="submit"
                        className="bg-blue-500 text-white py-2 px-4 rounded w-full my-2 hover:bg-blue-600 h-12 justify-center flex items-center disabled:opacity-50 dark:bg-slate-700 md:dark:hover:bg-slate-600"
                    >
                        {loading ?
                            <RotatingLines
                                strokeColor="#fff"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="20"
                                wrapperClassName="mx-auto"
                                visible={true}
                            />
                            :
                            t('登录')
                        }
                    </button>
                    <Link to={'/signup'} className={'text-blue-500 text-sm'}>{t('没有账号？点此注册')}</Link>
                    <br/>
                    <Link to={'/forgot-password'} className={'text-blue-500 text-sm'}>{t('忘记密码？')}</Link>
                </form>
                {error && (
                    <p className="text-red-500 text-sm mt-2">{error}</p>
                )}
                <div className={'mt-10 dark:text-white'}>
                    <br/>
                    <span className={'text-red-500 font-bold'}>{t('请不要轻易相信模型给出的任何回答，必要时请自行查证，本项目不对任何回答的准确性负责。')}</span>
                    <br/>
                    <br/>
                    {t('来吧进群保持关注不迷路：')} <a
                    className={'bg-blue-500 rounded text-white px-2 py-1 hover:bg-blue-700 transition-colors'}
                    href={'https://t.me/joinchat/DGZalT6pMAHNNg8JyfPLXw'} target={'_blank'} rel="noreferrer">{t('落格工作室')}</a>
                </div>
            </div>
            <span className={'text-xs text-gray-500 mt-5'}>{t('该页面主要由落格智聊编写实现')} : )</span>
        </div>
    );
};

export default Login;

