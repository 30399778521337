import { copyElementToClipboard, copySvg } from '../Utils';
import React from 'react';

function CopyButton({ element }) {
    return (
        <button className={'text-sm float-right border rounded p-1 border-gray-700 text-gray-500 hover:bg-gray-200'}
                onClick={(e) => copyElementToClipboard(e, element)}>{copySvg}</button>
    );
}

export default CopyButton;
