/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}


/**
 *
 * @param selected
 * @param setSelected
 * @param disabled
 * @param {{name: string, feature: string}[]} options
 * @returns {JSX.Element}
 * @constructor
 */
export default function ModelSelection({ selected, setSelected, disabled, options }) {

    return (
        <Listbox value={selected} onChange={setSelected}>
            {({ open }) => (
                <>
                    <div className="mt-1 relative grow">
                        <Listbox.Button
                            className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default sm:text-sm">
                            <span className="w-full inline-flex truncate">
                                <span className="">{disabled ? '' : selected.name.toUpperCase()}</span>
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options
                                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {options.map((item) => (
                                    <Listbox.Option
                                        key={item.name}
                                        className={({ active }) =>
                                            classNames(
                                                active ? 'text-white md:bg-blue-700 dark:bg-slate-700' : 'text-gray-900',
                                                'cursor-default select-none relative py-2 pl-3 pr-9'
                                            )
                                        }
                                        value={item}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <div className="flex flex-col">
                                                  <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                                                    {item.name.toUpperCase()}
                                                  </span>
                                                    <span
                                                        className={classNames(active ? 'text-indigo-200' : 'text-gray-500', 'ml-2 whitespace-pre-wrap')}>
                                                    {item.feature}
                                                  </span>
                                                </div>

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active ? 'text-white' : 'text-indigo-600',
                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                        )}
                                                    >
                                                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true"/>
                                                  </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    );
}

