import { useContext, useEffect, useRef, useState } from 'react';
import { isElectron, removeConversationById } from '../Utils';
import SimpleBar from 'simplebar-react';
import { useTranslation } from 'react-i18next';
import { XCircleIcon, PencilSquareIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import { isDesktopVersion, isMobileVersion, setConversation, getConversionById, clearConversations } from '../Utils';
import smalltalk from 'smalltalk';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { GlobalContext } from '../Contexts/GlobalContext';
import { Grid } from 'react-loader-spinner';

const modelShortNames = {
    'claude-3.5-sonnet':'S3.5',
    'claude-3-opus':'O',
    'claude-3-sonnet':'S',
    'claude-3-haiku':'H',
    'gemini-1.5-flash':'G',
    'gpt-4':'4',
    'gpt-4o':'4O',
    'gpt-4o-mini':'4OM',
    'gpt-4-turbo':'4T',
    'gpt-3.5-turbo':'G3.5',
    'gemini-pro':'G', //keep this for compatibility, now gpt-4-turbo released, no need for preview
    'gpt-4-turbo-preview':'4T', //keep this for compatibility, now gpt-4-turbo released, no need for preview
    'gpt-3.5-turbo-16k':'3.5', //keep this for compatibility, now 3.5 and 3.5-16k are the same
}



export default function LeftBar({ className }) {
    const {
        conversationId: currentId,
        setConversationId: setId,
        flip,
        setFlip,
        authUser,
        isDarkMode,
        setDarkMode,
        setHideLeftBar,
        inputCache,
        imageCache,
        config,
        globalLoading,
        conversationIdList,
        setConversationIdList,
        startNewConversation,
    } = useContext(GlobalContext);
    const liRefs = useRef([]);
    const { t, i18n } = useTranslation();
    const [updated, setUpdated] = useState(false);
    const [internalConversations, setInternalConversations] = useState([]);

    useEffect(() => {
        const pList = conversationIdList.map((id) => getConversionById(id));
        Promise.all(pList).then((list) => {
            setInternalConversations(list);
        });
        const index = conversationIdList.indexOf(currentId);
        if (index > 0 && index < liRefs.current.length && isDesktopVersion()) {
            liRefs.current[index].scrollIntoView({ behavior: 'smooth' });
        }
    }, [conversationIdList, currentId, flip]);

    useEffect(() => {
        if (currentId === -1) {
            // setId(conversations.length - 1);
            if (internalConversations.length > 0) {
                liRefs.current[internalConversations.length - 1].scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [internalConversations, currentId]);

    const clearAllConversations = () => {
        // ask for confirmation
        if (!window.confirm(t('确定要清空所有会话吗？'))) {
            return;
        }
        clearConversations();
        setConversationIdList([]);
        setId(-1);
    };

    async function deleteConversation(event, index) {
        event.preventDefault();
        event.stopPropagation();
        const newConversationIdList = [...conversationIdList];
        const id = conversationIdList[index];
        newConversationIdList.splice(index, 1);
        // ask for confirmation
        const conv = await getConversionById(id)
        if (!window.confirm(t(t('确定要删除这个会话吗？')+`(${conv.title}...)`))) {
            return;
        }
        setConversationIdList(newConversationIdList);
        await removeConversationById(conv.id);
        const currentIdIndex = conversationIdList.indexOf(currentId);
        if (currentId < index) return;
        setTimeout(() => {
            if (currentIdIndex >= index) {
                if (conversationIdList.length > 0) {
                    setId(currentId - 1);
                } else {
                    setId(-1);
                }
            }
        }, 100);

    }

    function toggleTheme() {
        setDarkMode(!isDarkMode);
    }

    function toggleLanguage() {
        if (i18n.resolvedLanguage === 'en') {
            i18n.changeLanguage('zh-Hans');
        } else if (i18n.resolvedLanguage === 'zh-Hans') {
            i18n.changeLanguage('zh-Hant');
        } else if (i18n.resolvedLanguage === 'zh-Hant') {
            i18n.changeLanguage('en');
        }
    }

    async function handleConversationClick(index) {
        // if currentIdList contain currentId
        const newId = conversationIdList[index];
        const currentConversation = await getConversionById(currentId);
        if (currentConversation) {
            if (inputCache.id === currentId) {
                currentConversation.inputCache = inputCache.text;
            }
            if (imageCache.id === currentId) {
                currentConversation.imageCache = imageCache.images;
            }
            await setConversation(currentConversation);
        }
        setId(newId);
        if (isMobileVersion() && currentId === newId) {
            // double tap to hide left bar
            setHideLeftBar(true);
        }
    }


// a little function to help us with reordering the result
    function reorder(list, startIndex, endIndex) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }

    function onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const newConversationIdList = reorder(
            conversationIdList,
            result.source.index,
            result.destination.index
        );

        setConversationIdList(newConversationIdList);
        // setId(-1);
        if (currentId === -1) return;

        setTimeout(() => {
            if (currentId === result.source.index) {
                setId(result.destination.index);
            }

            if ( result.source.index < currentId) {
                if (currentId <= result.destination.index) {
                    setId(currentId - 1);
                } else if (currentId > result.destination.index) {
                    setId(currentId);
                }
            }

            if ( result.source.index > currentId) {
                if (currentId < result.destination.index) {
                    setId(currentId);
                } else if (currentId >= result.destination.index) {
                    setId(currentId + 1);
                }
            }

        }, 100);
    }

    async function handleEditConversationTitle(event, index) {
        event.preventDefault();
        event.stopPropagation();
        const selectedId = conversationIdList[index];
        const selectedConversation = await getConversionById(selectedId);
        if (isElectron()) {
            smalltalk
                .prompt('', t('请输入新的会话标题\n⚠️注意不要在模型回答时进行编辑，避免数据丢失。'), selectedConversation.title)
                .then(async (value) => {
                    selectedConversation.title = value;
                    await setConversation(selectedConversation);
                })
                .catch(() => {
                    console.log('cancel');
                });
        } else {
            // ask for a new title
            const newTitle = window.prompt(t('请输入新的会话标题\n⚠️注意不要在模型回答时进行编辑，避免数据丢失。'), selectedConversation.title);
            if (newTitle === null) {
                return;
            }
            selectedConversation.title = newTitle;
            await setConversation(selectedConversation);
        }
        setFlip(!flip);
    }

    function UserInfoBlock() {
        if (globalLoading) {
            return (
                <Grid
                    height="20"
                    width="20"
                    color="#1D4ED8"
                    ariaLabel="grid-loading"
                    radius="10.5"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            );
        } else {
            if (authUser) {
                return <div role={'navigation'}>{authUser.username}, <Link to={'/logout'} role={'button'}>{t('登出')}</Link></div>;
            } else {
                return (<div>
                    <Link role={'button'}
                          className={'px-2 py-1 rounded dark:bg-blue-800 mr-2 text-white md:hover:dark:bg-blue-700 bg-blue-500 md:hover:bg-blue-600'}
                          to={'/login'}>{t('登录')}</Link>
                    {'/'}
                    <Link to={'/signup'} role={'button'}
                          className={'px-2 py-1 rounded dark:bg-blue-800 mx-2 text-white md:hover:dark:bg-blue-700 bg-blue-500 md:hover:bg-blue-600'}
                    >{t('注册')}</Link>
                </div>);
            }
        }
    }

    return (
        <div className={className + ` leftBar bg-gray-200 dark:bg-slate-800 h-full ${isDesktopVersion() ? 'w-1/3' : 'w-full pt-14'} flex flex-col `}>
            <button
                className="transition-colors duration-200 ease-in-out mt-5 mx-5 dark:bg-slate-700 md:dark:hover:bg-slate-600 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={authUser === null && !config.token}
                onClick={startNewConversation}>
                {t('开启新会话')}
            </button>
            <SimpleBar forceVisible="y" autoHide={true} className={'grow mx-0 px-5 h-1/2 py-2'} ariaLabel={'会话列表'}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <ul {...provided.droppableProps}
                                ref={provided.innerRef}>
                                {internalConversations.map((conversation, index) => (
                                    <Draggable key={index} draggableId={`${index}`} index={index}>
                                        {(provided, snapshot) => (
                                            <li key={index}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={(el) => {
                                                    provided.innerRef(el);
                                                    liRefs.current[index] = el;
                                                }}
                                                className={'transition-colors duration-200 ease-in-out group flex justify-between whitespace-nowrap  px-3 w-full rounded hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer text-gray-800 dark:text-gray-200 py-2 my-1  ' + (conversation.id === currentId ? ' bg-gray-100 dark:bg-gray-900' : '')}
                                                onClick={() => handleConversationClick(index)}
                                            >
                                                <div className={'whitespace-nowrap truncate'}>
                                                    {conversation.title}...
                                                </div>
                                                { conversation.model &&
                                                    <span className={'border border-gray-500 text-gray-500 rounded text-xs flex items-center px-2 -mr-2'}>
                                                        {modelShortNames[conversation.model] || ''}
                                                    </span>
                                                }
                                                <div className={'flex gap-1 absolute right-5 dark:bg-gray-900 bg-gray-100 invisible group-hover:visible px-3'}>
                                                    <button aria-label="编辑" className={'min-w-fit '}
                                                            onClick={(event) => handleEditConversationTitle(event, index)}>
                                                        <PencilSquareIcon className="w-6 h-6"/>
                                                    </button>
                                                    <button aria-label="删除" className={'min-w-fit '}
                                                            onClick={(event) => deleteConversation(event, index)}>
                                                        <XCircleIcon className="w-6 h-6"/>
                                                    </button>
                                                </div>
                                            </li>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </SimpleBar>
            <button
                className="m-5 mb-1  border border-red-500 dark:border-red-900 dark:hover:border-red-700 hover:dark:bg-red-900 hover:bg-red-100 py-2 px-4 rounded dark:text-white transition-colors duration-200 ease-in-out"
                onClick={clearAllConversations}>
                {t('清空会话历史')}
            </button>
            <div className={'flex justify-between text-sm p-5 dark:text-white gap-2'}>
                <a className=" border border-blue-400 dark:border-slate-700 hover:bg-blue-100 dark:hover:border-slate-600 hover:dark:bg-slate-900 py-2 px-4 rounded transition-colors duration-200 ease-in-out"
                   role={'button'}
                   href={'https://t.me/joinchat/DGZalT6pMAHNNg8JyfPLXw'} target="_blank" rel="noreferrer">
                    {t('Telegram 加群')}
                </a>
                <a className=" border border-blue-400 dark:border-slate-700 hover:bg-blue-100 dark:hover:border-slate-600 hover:dark:bg-slate-900 py-2 px-4 rounded transition-colors duration-200 ease-in-out"
                   role={'button'}
                   href={'https://im.logcg.com/archives/2399'} target="_blank" rel="noreferrer">
                    {t('常见问题解答')}
                </a>
            </div>

            <div className={'m-5 flex justify-between items-center dark:text-white'}>
                <div className={'flex items-center'}>
                    <button onClick={toggleTheme} aria-label="切换亮色暗色主题">
                        <div className="w-6 h-6">
                            {isDarkMode ?
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                     className="w-6 h-6">
                                    <path
                                        d="M12 2.25a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0V3a.75.75 0 01.75-.75zM7.5 12a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM18.894 6.166a.75.75 0 00-1.06-1.06l-1.591 1.59a.75.75 0 101.06 1.061l1.591-1.59zM21.75 12a.75.75 0 01-.75.75h-2.25a.75.75 0 010-1.5H21a.75.75 0 01.75.75zM17.834 18.894a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 10-1.061 1.06l1.59 1.591zM12 18a.75.75 0 01.75.75V21a.75.75 0 01-1.5 0v-2.25A.75.75 0 0112 18zM7.758 17.303a.75.75 0 00-1.061-1.06l-1.591 1.59a.75.75 0 001.06 1.061l1.591-1.59zM6 12a.75.75 0 01-.75.75H3a.75.75 0 010-1.5h2.25A.75.75 0 016 12zM6.697 7.757a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 00-1.061 1.06l1.59 1.591z"/>
                                </svg>

                                :
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                     className="w-5 h-5">
                                    <path fillRule="evenodd"
                                          d="M9.528 1.718a.75.75 0 01.162.819A8.97 8.97 0 009 6a9 9 0 009 9 8.97 8.97 0 003.463-.69.75.75 0 01.981.98 10.503 10.503 0 01-9.694 6.46c-5.799 0-10.5-4.701-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 01.818.162z"
                                          clipRule="evenodd"/>
                                </svg>

                            }
                        </div>
                    </button>
                    {!isElectron() &&
                        <footer className={'flex'}>
                            /
                            <button onClick={toggleLanguage} aria-label="切换繁简英界面">
                                <div className="w-6 h-6">
                                    {t(i18n.resolvedLanguage)}
                                </div>
                            </button>
                        </footer>
                    }


                </div>
                <button className={'text-xs text-gray-500'} onClick={()=>{
                    if (!isElectron()) {
                        setUpdated(true);
                        return;
                    }
                    window.electronAPI.onNoNewVersionAvailable( (event, arg) => {
                        setUpdated(true);
                    });
                    window.electronAPI.checkForUpdate();
                }}>
                    v{process.env.REACT_APP_VERSION} {updated ? '✅' : ''}
                </button>
                <UserInfoBlock/>
            </div>
        </div>
    );
}
