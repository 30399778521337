import { fetchAuthSession } from 'aws-amplify/auth';
import {
    genHistoryForOpenAI,
    getServerAPI,
    isDesktopVersion
} from '../Utils';
import { isExpired } from 'react-jwt';


const host = process.env.REACT_APP_D === 'true' ? 'http://127.0.0.1:5000' : 'https://api.chatai.beauty';
let chatAIEndpoint = host + '/v1';


if (process.env.REACT_APP_D !== 'true') {
    getServerAPI(host).then((serverAPI) => {
        if (serverAPI) {
            chatAIEndpoint = serverAPI + '/v1';
        }
    });
}


function getLatestVersionInfo() {
    return fetch(chatAIEndpoint + '/client/version', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(res => res.json());
}


/**
 *
 * @returns {Promise<String>}
 */
function getServerToken() {
    return new Promise((resolve, reject) => {
        fetchAuthSession().then((session) => {
            const accessToken = session.tokens.idToken.toString();
            if (isExpired(accessToken)) {
                // now token will expire in 1 day, long enough
                fetchAuthSession({ forceRefresh: true }).then(session => {
                    const accessToken = session.tokens.idToken.toString();
                    resolve(accessToken);
                });
                // do whatever you want to do now :)
            } else {
                resolve(accessToken);
            }
        }).catch((err) => {
            reject(err);
        });
    });

}

function getPriceCategory(user) {
    return getServerToken(user)
        .then((accessToken) => fetch(chatAIEndpoint + '/store?get_price_cat=1', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        }))
        .then(res => res.json());
}

/**
 * {
 *     "_updated_at": "2023-02-06T11:53:32.888638+0000",
 *     "credit": 0,
 *     "sub": "92df14c2-d58c-44eb-9efb-edcdd6361aa7"
 *     "tg_id": "123456789", // telegram id if exist
 *     "config": {} // if exist
 * }
 * @returns {Promise<any>}
 */
function getUserInfo(user) {
    return getServerToken(user)
        .then((accessToken) => fetch(chatAIEndpoint + '/user', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        })).then(res => res.json());
}


/**
 *
 * @param user
 * @param priceCategory
 * @returns {Promise<string>}
 */
function getPlaceOrderUrl(user, priceCategory) {
    return getServerToken(user)
        .then((accessToken) => fetch(chatAIEndpoint + `/store?price_cat=${priceCategory}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        })).then(res => res.json()).then(data => {
            if (isDesktopVersion()) {
                return data['url_qrcode'];
            } else {
                return data.url;
            }
        });
}


function setUserTgId(user, tgId) {
    return getServerToken(user)
        .then((accessToken) => fetch(chatAIEndpoint + `/user/tgid`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ tgId })
        })).then(res => res.json());
}


function resetAccessToken(user) {
    return getServerToken(user)
        .then((accessToken) => fetch(chatAIEndpoint + `/user/reset-token`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        })).then(res => res.json());
}


function setUserConfig(user, config) {
    const filtered = { ...config };
    delete filtered.token;
    delete filtered.prompt;

    return getServerToken(user)
        .then((accessToken) => fetch(chatAIEndpoint + `/user/config`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ config: filtered })
        })).then(res => res.json());
}


function sendConversationToEmail(user, conversation, title) {
    let body = {};
    return genHistoryForOpenAI(conversation)
        .then((history) => {
            body = {
                'chat_title': title,
                'chat_history': history,
            };
        })
        .then(() => getServerToken(user))
        .then((accessToken) => fetch(chatAIEndpoint + `/mail-history`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(body)
        })).then(res => res.text());
}

export {
    getServerToken,
    getPriceCategory,
    getUserInfo,
    getPlaceOrderUrl,
    setUserTgId,
    sendConversationToEmail,
    setUserConfig,
    resetAccessToken,
    getLatestVersionInfo,
};
