import React, { useState } from 'react';
import { confirmSignUp, resendSignUpCode } from 'aws-amplify/auth';
import { useLocation } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const VerifyEmail = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [email, setEmail] = useState(location.state && location.state.email ? location.state.email : null);
    const [username, setUsername] = useState(location.state && location.state.username ? location.state.username : '');
    const [verifyCode, setVerifyCode] = useState('');

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            await confirmSignUp({ username, confirmationCode: verifyCode });
            setError(t('账号已确认，请登录。'));
            // show a js alert
            alert(t('账号已确认，请登录。'));
            // redirect to login page
            navigate('/login');
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleResend = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            await resendSignUpCode({ username });
            setError(t('验证码已发送，请检查您的电子邮件。'));
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="pt-10 md:p-10 justify-center flex flex-col text-center dark:bg-black h-screen">
            <div className={'bg-blue-50 py-20 pt-10 mx-5 md:px-10 md:w-1/2 rounded-xl md:mx-auto px-5 dark:bg-slate-900'}>
                <div className={'px-2 mb-10 w-1/2 rounded-xl mx-auto text-lg font-bold text-gray-500 dark:text-gray-300'}>
                    {t('账号验证')}
                </div>
                <form onSubmit={handleSubmit} className={'mx-auto max-w-sm'}>
                    {email &&
                        <input
                            type="email"
                            className="block border border-gray-400 p-2 mb-2 rounded w-full dark:text-white dark:bg-slate-700 dark:border-slate-700"
                            placeholder={t('邮箱地址')}
                            contentEditable={false}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    }

                    <input
                        type="text"
                        className="block border border-gray-400 p-2 mb-2 rounded  w-full dark:text-white dark:bg-slate-700 dark:border-slate-700"
                        placeholder={t('用户名')}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <input
                        type="text"
                        className="block border border-gray-400 p-2 mb-2 rounded  w-full dark:text-white dark:bg-slate-700 dark:border-slate-700"
                        placeholder={t('验证码')}
                        value={verifyCode}
                        onChange={(e) => setVerifyCode(e.target.value)}
                    />
                    <button

                        disabled={loading || !username || !verifyCode}
                        type="submit"
                        className="bg-blue-500 text-white py-2 px-4 rounded w-full my-2 hover:bg-blue-600 h-12 justify-center flex items-center dark:bg-slate-700 md:dark:hover:bg-slate-600"
                    >
                        {loading ?
                            <RotatingLines
                                strokeColor="#fff"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="20"
                                wrapperClassName="mx-auto"
                                visible={true}
                            />
                            :
                            t('提交')
                        }
                    </button>
                </form>
            </div>

            {error && (
                <p className="text-red-500 text-sm mt-2">{error}</p>
            )}

            <div className={'text-sm text-gray-600 font-bold mt-5 md:mx-2 mx-5'}>
                {t('未收到验证码？请耐心等待几分钟，看看垃圾邮件箱如果还是没有收到，请尝试：')}
                <br/>
                <button className={'px-2 py-1 rounded bg-blue-300 hover:bg-blue-500 mt-2 text-white'} disabled={loading}
                        onClick={handleResend}>{t('重发验证码')}</button>
                <br/><br/>
                <span className={'text-xs text-gray-500'}>{t('该页面主要由落格智聊编写实现')} : )</span>
            </div>
        </div>
    );
};

export default VerifyEmail;

