import React, { useEffect, useState } from 'react';
import { signUp } from 'aws-amplify/auth';
import { RotatingLines } from 'react-loader-spinner';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowLeftCircleIcon } from '@heroicons/react/24/solid';


const Signup = () => {
    const { t} = useTranslation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            await signUp({
                username,
                password,
                options: {
                    userAttributes: { email },
                },
            });
            setError(t('注册成功，请检查您的电子邮件以获取验证码。'));
            navigate('/verify-email', { state: { username: username, email: email } });
        } catch (error) {
            if (!error.message) {
                setError(t('发生错误，请重试'));
            }
            // if containing Password not long enough
            if (error.message.includes('Password not long enough')) {
                setError(t('密码长度不足'));
            } else if (error.message.includes('have uppercase characters')) {
                setError(t('密码必须包含大写字母'));
            } else if (error.message.includes('have lowercase characters')) {
                setError(t('密码必须包含小写字母'));
            } else if (error.message.includes('have numeric characters')) {
                setError(t('密码必须包含数字'));
            }  else if (error.message.includes('Username cannot be empty')) {
                setError(t('用户名不能为空'));
            } else if (error.message.includes('Password cannot be empty')) {
                setError(t('密码不能为空'));
            } else if (error.message.includes('Invalid email address format')) {
                setError(t('邮箱地址格式不正确'));
            } else if (error.message.includes('User already exists')) {
                setError(t('用户已存在'));
            } else if (error.message.includes('An account with the given email already exists')) {
                setError(t('邮箱已被注册'));
            } else if (error.message) setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (password !== password2) {
            setError(t('两次输入的密码不一致'));
        } else {
            setError('');
        }
    }, [password2, password]);  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="pt-10 md:p-10 justify-center flex flex-col text-center dark:bg-black h-screen">
            <div className={'bg-blue-50 py-20 pt-10 mx-5 md:px-10 md:w-1/2 rounded-xl md:mx-auto px-5 dark:bg-slate-900'}>
                <div className={'text-gray-500 w-full text-left'}>
                    <ArrowLeftCircleIcon className={'w-8 h-8'} onClick={() => navigate(-1)} aria-label={t('返回')}/>
                </div>
                <div className={'px-2 mb-10 w-1/2 rounded-xl mx-auto text-lg font-bold text-gray-500 dark:text-gray-300'}>
                    {t('注册账号')}
                </div>
                <form onSubmit={handleSubmit} className={'mx-auto max-w-sm'}>
                    <input
                        type="email"
                        className="block border border-gray-400 p-2 mb-2 rounded w-full dark:text-white dark:bg-slate-700 dark:border-slate-700"
                        placeholder={t('邮箱地址')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="text"
                        className="block border border-gray-400 p-2 mb-2 rounded  w-full dark:text-white dark:bg-slate-700 dark:border-slate-700"
                        placeholder={t('登录用户名')}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <input
                        type="password"
                        className="block border border-gray-400 p-2 mb-2 rounded  w-full dark:text-white dark:bg-slate-700 dark:border-slate-700"
                        placeholder={t('密码')}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input
                        type="password"
                        className="block border border-gray-400 p-2 mb-2 rounded  w-full dark:text-white dark:bg-slate-700 dark:border-slate-700"
                        placeholder={t('确认密码')}
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                    />
                    <button
                        disabled={loading || !email || !username || !password || !password2 || password !== password2}
                        type="submit"
                        className="bg-blue-500 text-white py-2 px-4 rounded w-full my-2 hover:bg-blue-600 h-12 justify-center flex items-center disabled:opacity-50 dark:bg-slate-700 md:dark:hover:bg-slate-600"
                    >
                        {loading ?
                            <RotatingLines
                                strokeColor="#fff"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="20"
                                wrapperClassName="mx-auto"
                                visible={true}
                            />
                            :
                            t('注册')
                        }
                    </button>
                    <Link to={'/login'} className={'text-blue-500'}>{t('已有账号？点此登录')}</Link>
                </form>
            </div>

            {error && (
                <p className="text-red-500 text-sm mt-2">{error}</p>
            )}

            <div className={'text-sm text-gray-600 dark:text-gray-400 font-bold mt-5 md:mx-auto px-10 md:w-1/2'}>
                {t('注册即表示您同意落格智聊的')}
                <a className={'ml-2'} href={t('privacy_policy_link')}>{t('隐私政策')}</a>
                <br/>
                {t('提交注册后您的邮箱会收到一个验证码，请在接下来的页面中输入验证码以完成注册。')}
                <br/><br/>
                <span className={'text-xs dark:text-gray-600 text-gray-500'}>{t('该页面主要由落格智聊编写实现')} : )</span>
            </div>
        </div>
    );
};

export default Signup;

