import React, { useEffect, useRef, useState } from 'react';

const InlineDropdown = ({ options, prompt, selected, setSelected, disabled }) => {
    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef(null);
    const dropdownRef = useRef(null);

    const handleOptionClick = (option) => {
        setSelected(option);
        setIsOpen(false);
    };

    useEffect(() => {
        if (isOpen) {
            const handleOutsideClick = (event) => {
                if (
                    dropdownRef.current &&
                    !dropdownRef.current.contains(event.target) &&
                    !buttonRef.current.contains(event.target)
                ) {
                    setIsOpen(false);
                }
            };

            document.addEventListener('mousedown', handleOutsideClick);

            return () => {
                document.removeEventListener('mousedown', handleOutsideClick);
            };
        }
    }, [isOpen]);

    return (
        <div className={'relative inline-block dark:text-gray-300 text-gray-700 pl-3'}>
            <button
                ref={buttonRef}
                disabled={disabled}
                onClick={() => setIsOpen(!isOpen)}
                className="bg-blue-500 dark:bg-slate-700 px-2 py-1 rounded text-white disabled:opacity-50"
            >
                <span>{prompt}</span>
                {selected.toUpperCase()}
                <span className={'ml-1'}>{isOpen ? '▼' : '▲'}</span>
            </button>
            {isOpen && (
                <ul ref={dropdownRef}
                    className="dropdown-options absolute z-10 mt-2 py-1 w-full dark:bg-gray-700 bg-gray-200 rounded shadow-lg border dark:border-gray-700 border-gray-300 bottom-full">
                    {options.map((option) => (
                        <li
                            key={option}
                            onClick={() => handleOptionClick(option)}
                            className="cursor-pointer py-2 px-4 dark:hover:bg-gray-800 hover:bg-gray-300"
                        >
                            {option.toUpperCase()}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default InlineDropdown;
