import { signOut } from 'aws-amplify/auth';
import React, { useEffect, useState } from 'react';
import { Grid } from 'react-loader-spinner';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Ensure screen readers only see the modal when it's open
Modal.setAppElement('#root');

export default function Logout() {
    const { t} = useTranslation();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Show the modal on page load
        setModalIsOpen(true);
    }, []);

    function handleConfirm() {
        setModalIsOpen(false);
        signOut()
            .then(() => {
                // Clear local storage
                localStorage.clear();
                navigate('/')
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleCancel() {
        navigate('/')
    }

    return (
        <div className="pt-10 md:p-10 justify-center flex flex-col text-center dark:bg-black h-screen">
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={handleCancel}
                contentLabel="Logout Confirmation"
                className="bg-white dark:bg-gray-800 rounded-md p-6 w-full max-w-lg mx-auto focus:outline-none"
                overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-50 dark:bg-opacity-80 flex items-center justify-center"
            >
                <h2 className="text-xl font-bold mb-6 text-gray-800 dark:text-gray-100">{t('确认登出')}</h2>
                <p className="text-gray-700 dark:text-gray-300">{t('登出将清空浏览器缓存，所有会话历史都会永久清空！')}</p>
                <div className="mt-6">
                    <button
                        className="bg-blue-500 dark:bg-blue-600 text-white px-4 py-2 rounded-md mr-3 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 focus:outline-none"
                        onClick={handleConfirm}
                    >
                        {t('登出')}
                    </button>
                    <button
                        className="bg-gray-200 dark:bg-gray-700 text-black dark:text-gray-200 px-4 py-2 rounded-md focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:outline-none"
                        onClick={handleCancel}
                    >
                        {t('取消')}
                    </button>
                </div>
            </Modal>
            {!modalIsOpen &&
                <div className={'bg-blue-50 py-20 pt-10 mx-5 md:px-10 md:w-1/2 rounded-xl md:mx-auto dark:bg-slate-900'}>
                    <div className={'md:px-2 mb-2 md:w-1/2 rounded-xl mx-auto text-lg font-bold text-gray-500 dark:text-gray-300'}>
                        {t('正在清空浏览器缓存')}……
                    </div>
                    <span className={'text-xs text-gray-500'}>{t('该页面主要由落格智聊编写实现')} : )</span>
                    <Grid
                        height="40"
                        width="40"
                        color="#3B82F6"
                        ariaLabel="grid-loading"
                        radius="10.5"
                        wrapperStyle={{}}
                        wrapperClass="justify-center mt-10"
                    />
                </div>
            }
        </div>
    );
}
